import { XCircleIcon } from "@heroicons/react/24/outline";
import { CSSProperties, DetailedHTMLProps, InputHTMLAttributes, useRef } from "react";
import { StyledButton } from "../StyledButton";
import { InfoTooltipIcon } from "../InfoTooltip";

export interface CoolTextAreaProps {
  textAreaProps?: DetailedHTMLProps<InputHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>;
  value?: string;
  onBlur?: () => {};
  onChange: (newVal?: string) => void;
  showClearButton?: boolean;
  placeholder?: string;
  rightButtonOptions?: {
    text: string;
    onClick: () => Promise<void>;
    width?: "w-8" | "w-16" | "w-24" | "w-48";
    className?: string;
    disabled?: boolean;
  };
  multiline?: boolean;
  isPercent?: boolean;
  isMoney?: boolean;
  style?: CSSProperties;
}

export interface CoolTextAreaWithLabelProps extends CoolTextAreaProps {
  label: string;
  infoTooltip?: string;
  subtitle?: string;
}

export function CoolTextArea(p: CoolTextAreaProps) {
  if (p.showClearButton && p.rightButtonOptions) {
    throw new Error("Cannot use clear button and a custom right button");
  }
  return (
    <div className="relative">
      <textarea
        value={p.value}
        onChange={newVal => {
          p.onChange(newVal.target.value);
        }}
        style={p.style}
        placeholder={p.placeholder}
        {...p.textAreaProps}
        className={`block w-full border border-gray-300 ${
          p.isMoney ? "pl-7" : ""
        } rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm h-32 ${
          p.textAreaProps?.className ? ` ${p.textAreaProps.className}` : ""
        } ${p.textAreaProps?.disabled ? "bg-gray-100" : 0}`}
      />
      {p.rightButtonOptions ? (
        <StyledButton
          className={`cursor-pointer absolute inset-y-0 right-1 flex items-center z-10 mt-1 mb-1 h-8 ${
            p.rightButtonOptions.className
          } ${p.rightButtonOptions.width ?? "w-24"}`}
          text={p.rightButtonOptions.text}
          onClick={p.rightButtonOptions.onClick}
          disabled={p.rightButtonOptions.disabled}
        />
      ) : null}
      {p.value && p.showClearButton ? (
        <div
          onClick={() => {
            p.onChange("");
          }}
          className="cursor-pointer absolute inset-y-0 right-4 flex items-center pl-3 z-10"
        >
          <XCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />
        </div>
      ) : null}
      {p.isMoney ? <div className="cursor-pointer absolute inset-y-0 left-3 flex items-center pr-3 z-0">$</div> : null}
      {p.isPercent ? <div className="cursor-pointer absolute inset-y-0 right-2 flex items-center pl-3 z-0">%</div> : null}
    </div>
  );
}

export function CoolTextAreaWithLabel(p: CoolTextAreaWithLabelProps) {
  const refId = useRef("_" + Math.random().toString() + Math.random().toString()).current;
  const id = p.textAreaProps?.id || refId;

  return (
    <div style={p.style}>
      <div>
        <label htmlFor={id} className="flex items-center text-sm font-extrabold">
          {p.label}
          {p.infoTooltip ? <InfoTooltipIcon title={p.infoTooltip} /> : null}
        </label>
        {p.subtitle ? <label className="text-xs font-medium text-primary">{` ${p.subtitle}`}</label> : null}
      </div>
      <div className="relative mt-1 rounded-md shadow-sm">
        <CoolTextArea
          {...p}
          style={undefined} // This is to avoid the style being applied to the container here AND the input inside CoolTextArea
          value={p.value ?? ""}
          onChange={newVal => {
            p.onChange(newVal ?? "");
          }}
          textAreaProps={{ ...p.textAreaProps, id }}
        />
      </div>
    </div>
  );
}
