import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker";
import { InfoTooltipIcon } from "../InfoTooltip";

export type CoolDateInputProps = Omit<ReactDatePickerProps, "selected" | "value"> & {
  label?: string;
  value?: Date;
  isClearable?: boolean;
  infoTooltip?: string;
};

export function CoolDateInput(p: CoolDateInputProps) {
  const { label, className, value, ...rest } = p;

  return (
    <div className={className}>
      {p.label ? (
        <div className="flex text-sm font-extrabold items-center mb-1">
          <label htmlFor="text">{label}</label>
          {p.infoTooltip ? <InfoTooltipIcon title={p.infoTooltip} /> : null}
        </div>
      ) : null}
      <ReactDatePicker
        selected={value}
        preventOpenOnFocus
        wrapperClassName="w-full"
        className={`block w-full rounded-md cursor-pointer shadow-sm border border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm h-10 z-50 ${
          p.disabled ? "bg-gray-100" : ""
        } ${p.className}`}
        {...rest}
      />
    </div>
  );
}
