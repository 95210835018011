import { CSSProperties, useState } from "react";
import Select from "react-select";
import { InfoTooltipIcon } from "../InfoTooltip";
import _ from "lodash";

export type SelectOption<T = string> = {
  value: T;
  label: string;
};

export type CoolMultiSelectInputProps = {
  value?: SelectOption[];
  options: SelectOption[];
  isMulti?: boolean;
  freeFormOptionMagicValue?: string;
  onChange: (newVals: SelectOption[]) => void;
  className?: string;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  width?: number;
  disabled?: boolean;
  placeholder?: string;
  containerClassName?: string;
  containerStyle?: CSSProperties;
};

export type CoolMultiSelectInputWithLabelProps = CoolMultiSelectInputProps & {
  label: string;
  style?: CSSProperties;
  infoTooltip?: string;
};

export function CoolMultiSelectInput(p: CoolMultiSelectInputProps) {
  const [freeformOption, setFreeformOption] = useState("");

  return (
    <div style={p.containerStyle} className={p.containerClassName}>
      <Select
        value={p.value}
        isMulti={p.isMulti ?? true}
        isDisabled={!!p.disabled}
        placeholder={p.placeholder}
        onFocus={p.onFocus}
        onBlur={e => {
          setFreeformOption("");
          p.onBlur?.(e);
        }}
        menuPortalTarget={document.body}
        menuPosition="fixed"
        options={_(p.options)
          .concat((freeformOption ? { label: freeformOption, value: p.freeFormOptionMagicValue } : null) as any)
          .compact()
          .value()}
        onInputChange={newVal => {
          if (newVal && p.freeFormOptionMagicValue) {
            setFreeformOption(newVal);
          }
        }}
        onChange={newVal => {
          p.onChange(p.isMulti === false ? ([newVal] as SelectOption[]) : (newVal as SelectOption[]));
        }}
        styles={{
          input: a => {
            return { ...a, height: 30, width: p.width };
          },
          dropdownIndicator: a => {
            return {
              ...a,
              cursor: "pointer"
            };
          },
          menuPortal: a => ({ ...a, zIndex: 60 }),
          clearIndicator: a => {
            return {
              ...a,
              cursor: "pointer"
            };
          }
        }}
        className={`block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm  ${
          p.className
        } ${p.disabled ? "bg-gray-100" : 0}`}
      />
    </div>
  );
}

export function CoolMultiSelectWithLabel(p: CoolMultiSelectInputWithLabelProps) {
  const { style, label, ...rest } = p;
  return (
    <div style={p.style}>
      <label className="flex text-sm font-extrabold items-center">
        {p.label} {p.infoTooltip ? <InfoTooltipIcon title={p.infoTooltip} /> : null}
      </label>
      <div className="relative mt-1 rounded-md shadow-sm">
        <CoolMultiSelectInput {...rest} />
      </div>
    </div>
  );
}
