import { XCircleIcon } from "@heroicons/react/24/outline";
import {
  CSSProperties,
  DetailedHTMLProps,
  FocusEventHandler,
  HTMLInputTypeAttribute,
  InputHTMLAttributes,
  MutableRefObject,
  ReactNode,
  useRef
} from "react";
import { StyledButton } from "../StyledButton";
import clsx from "clsx";
import { twMerge } from "tailwind-merge";
import { Info } from "react-feather";
import { InfoTooltipIcon } from "../InfoTooltip";
import { openErrorToast } from "../../utils/openErrorToast";
import { translate } from "@ollie-sports/i18n";

export interface CoolTextInputProps {
  inputProps?: DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
  value?: string;
  onBlur?: () => {};
  onChange: (newVal?: string) => void;
  showClearButton?: boolean;
  placeholder?: string;
  type?: HTMLInputTypeAttribute;
  rightButtonOptions?: {
    text: string;
    onClick: () => Promise<void>;
    width?: "w-8" | "w-16" | "w-24" | "w-48";
    className?: string;
    disabled?: boolean;
  };
  innerRef?: MutableRefObject<HTMLInputElement | null>;
  isLoading?: boolean;
  multiline?: boolean;
  isPercent?: boolean;
  isMoney?: boolean;
  style?: CSSProperties;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  autoComplete?: string;
}

export interface CoolTextInputWithLabelProps extends CoolTextInputProps {
  label: string;
  infoTooltip?: string;
  subtitle?: string;
}

export function CoolTextInput(p: CoolTextInputProps) {
  if (p.showClearButton && p.rightButtonOptions) {
    throw new Error("Cannot use clear button and a custom right button");
  }

  const inputRef = useRef<HTMLInputElement | null>(null);

  const Component = (p.multiline ? "textarea" : "input") as "input";

  return (
    <div className="relative" style={p.style}>
      <Component
        ref={ref => {
          inputRef.current = ref;
          if (p.innerRef) {
            p.innerRef.current = ref;
          }
        }}
        autoComplete={p.autoComplete}
        value={p.value}
        onChange={e => {
          p.onChange(e.target.value);
          if (p.multiline && inputRef.current) {
            const scrollHeight = inputRef.current.scrollHeight;
            if (parseFloat(inputRef.current.style.height || "0") < scrollHeight) {
              inputRef.current.style.height = `${scrollHeight}px`;
            }
          }
        }}
        onFocus={p.onFocus}
        style={p.style}
        placeholder={p.placeholder}
        onBlur={p.onBlur}
        {...p.inputProps}
        type={p.inputProps?.type ?? p.type ?? "text"}
        className={twMerge(
          `block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm h-10`,
          p.isMoney && "pl-7",
          p.inputProps?.className,
          p.inputProps?.disabled && "bg-gray-100"
        )}
      />
      {p.rightButtonOptions ? (
        <StyledButton
          className={`cursor-pointer absolute inset-y-0 right-1 flex items-center z-10 mt-1 mb-1 h-8 ${
            p.rightButtonOptions.className
          } ${p.rightButtonOptions.width ?? "w-24"}`}
          text={p.rightButtonOptions.text}
          onClick={p.rightButtonOptions.onClick}
          disabled={p.rightButtonOptions.disabled}
        />
      ) : null}
      {p.value && p.showClearButton && !p.isLoading ? (
        <div
          onClick={() => {
            p.onChange("");
          }}
          className="cursor-pointer absolute inset-y-0 right-4 flex items-center pl-3 z-10"
        >
          <XCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />
        </div>
      ) : null}
      {p.isMoney ? <div className="absolute inset-y-0 left-3 flex items-center pr-3 z-0 pointer-events-none">$</div> : null}
      {p.isPercent ? <div className="absolute inset-y-0 right-2 flex items-center pl-3 z-0 pointer-events-none">%</div> : null}
      {p.isLoading ? (
        <div className="cursor-pointer absolute inset-y-0 right-4 flex items-center pl-3 z-10">
          <img className="h-4 w-4" src="/static/loader.gif" />
        </div>
      ) : null}
    </div>
  );
}

export function CoolTextInputWithLabel(p: CoolTextInputWithLabelProps) {
  const refId = useRef("_" + Math.random().toString() + Math.random().toString()).current;
  const id = p.inputProps?.id || refId;
  return (
    <div style={p.style}>
      <div>
        <label htmlFor={id} className="flex text-sm font-extrabold items-center">
          {p.label} {p.infoTooltip ? <InfoTooltipIcon title={p.infoTooltip} /> : null}
        </label>
        {p.subtitle ? <div className="text-xs font-medium">{` ${p.subtitle}`}</div> : null}
      </div>
      <div className="relative mt-1 rounded-md">
        <CoolTextInput
          {...p}
          style={undefined} // This is to avoid the style being applied to the container here AND the input inside CoolTextInput
          value={p.value ?? ""}
          onChange={newVal => {
            p.onChange(newVal ?? "");
          }}
          inputProps={{ ...p.inputProps, id }}
        />
      </div>
    </div>
  );
}
