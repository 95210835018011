import clsx from "clsx";
import { CSSProperties, ChangeEventHandler, DetailedHTMLProps, MouseEvent, MouseEventHandler, useRef } from "react";
import { InfoTooltipIcon } from "../InfoTooltip";

export interface CoolSelectInputProps {
  value?: string;
  inputProps?: DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>;
  placeholder?: string;
  options: {
    value: string;
    label: string;
    disabled?: boolean;
  }[];
  label?: string;
  infoTooltip?: string;
  containerStyle?: CSSProperties;
  onBlur?: () => void;
  onChange: (newVal: string) => void;
  allowClear?: boolean;
  condensed?: boolean;
  rightComponent?: JSX.Element;
}

export function CoolSelectInput(p: CoolSelectInputProps) {
  const id = useRef(Math.random() + "" + Math.random()).current;
  return (
    <div style={p.containerStyle}>
      {p.label ? (
        <label htmlFor={id} className="flex text-sm font-extrabold items-center mb-1">
          {p.label} {p.infoTooltip ? <InfoTooltipIcon title={p.infoTooltip} /> : null}
        </label>
      ) : null}
      <div className="relative flex items-center">
        <select
          name={id}
          id={id}
          value={p.value ?? ""}
          placeholder={p.placeholder}
          {...p.inputProps}
          onBlur={p.onBlur}
          onChange={e => {
            p.onChange(e.target.value);
          }}
          className={clsx(
            `block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm h-10 cursor-pointer`,
            p.inputProps?.className,
            p.inputProps?.disabled && "bg-gray-100",
            !p.value && "text-gray-400",
            p.condensed && `h-5 py-0 px-2`
          )}
        >
          <option disabled={!p.allowClear} value="">
            {p.value ? "" : p.placeholder ?? ""}
          </option>
          {p.options.map(option => {
            return (
              <option disabled={!!option.disabled} key={option.value} value={option.value}>
                {option.label}
              </option>
            );
          })}
        </select>
        {p.rightComponent ? <div className="absolute right-10">{p.rightComponent}</div> : null}
      </div>
    </div>
  );
}
