import { DetailedHTMLProps, InputHTMLAttributes } from "react";
import { InfoTooltipIcon } from "../InfoTooltip";

export function CoolCheckboxInput(
  p: {
    label: string;
    labelType: "above" | "inside" | "both";
    infoTooltip?: string;
    renderInvisbleTopLabel?: boolean;
    subtitle?: string;
    fullWidth?: boolean;
    style?: any;
    value: boolean;
    className?: string;
    onChange: (newVal: boolean) => void;
  } & Omit<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, "value" | "onChange">
) {
  const { label, style, subtitle, labelType, fullWidth, renderInvisbleTopLabel, value, onChange, infoTooltip, ...inputProps } = p;
  const labelString = inputProps.required ? label + "*" : label;

  return (
    <div style={style} className={p.className}>
      <div>
        {labelType === "above" || labelType === "both" || renderInvisbleTopLabel ? (
          <div
            className={`flex text-sm font-extrabold ${labelType === "above" || labelType === "both" ? "visible" : "invisible"}`}
          >
            {labelString}
            {p.infoTooltip ? <InfoTooltipIcon title={p.infoTooltip} /> : null}
          </div>
        ) : null}
      </div>
      <div className="flex flex-col">
        <label htmlFor={label} className={`${fullWidth ? "flex" : "inline-flex"} relative h-10 items-center cursor-pointer`}>
          <input
            type={"checkbox"}
            name={labelString}
            checked={!!value}
            onFocus={inputProps.onFocus}
            onBlur={inputProps.onBlur}
            id={labelString}
            className="cursor-pointer rounded-md shadow-sm focus:ring-gray-300 sm:text-sm w-5 h-5"
            onChange={e => {
              p.onChange(e.target.checked);
            }}
            {...inputProps}
          />
          {labelType === "inside" || p.labelType === "both" ? (
            <label htmlFor={labelString} className={`flex items-center cursor-pointer text-sm font-bold ml-2 select-none`}>
              {labelString}
              {p.infoTooltip ? <InfoTooltipIcon title={p.infoTooltip} /> : null}
            </label>
          ) : null}
        </label>
        {subtitle ? <label className="text-xs font-medium text-gray-400">{` ${subtitle}`}</label> : null}
      </div>
    </div>
  );
}
